import React, { useEffect, useState } from 'react';
import * as S from '../ConsumerLayout.style';
// import { useTranslation } from 'react-i18next';
import Logo from '@app/assets/logo-v3.svg';
import Menus from '@app/components/layouts/ConsumerLayout/Logged/Menus';
import UserActions from '@app/components/layouts/ConsumerLayout/Logged/UserActions';
import { MenuOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import { responseApiGetInforUser } from '@app/modules/CustomerProfile/interfaces';
import { readToken } from '@app/services/localStorage.service';
import { getUserProfileApi } from '@app/modules/CustomerProfile/UpdateProfile/UpdateProfile.api';
import jwt_decode from 'jwt-decode';

type menuInterface = {
  key: string;
  url: string;
  label: string;
};
const Header: React.FC = () => {
  // const { t } = useTranslation();
  const [dataUser, setdataUser] = useState<responseApiGetInforUser>();
  const [openDropdownMenu, setopenDropdownMenu] = useState(false);
  const UserInfor: any = jwt_decode(readToken());
  const callGetInforUser = async () => {
    await getUserProfileApi(UserInfor && UserInfor.uuid)
      .then((res) => {
        setdataUser(res);
      })
      .catch((err) => {
        console.log(err);
        setdataUser(undefined);
      });
  };

  useEffect(() => {
    callGetInforUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const items: menuInterface[] = [
    {
      key: 'dashboard',
      url: '/boards',
      label: 'Dashboard',
    },
    {
      key: 'loan-management',
      url: '/loan-management',
      label: 'Quản lý giao dịch',
    },
    {
      key: 'connect-partner-management',
      url: '/connect-partner-management',
      label: 'Quản lý liên kết',
    },
    {
      key: 'logout',
      url: '/auth/logout',
      label: 'Đăng xuất',
    },
  ];

  function openDropdown() {
    setopenDropdownMenu(true);
  }

  function handleCancel() {
    setopenDropdownMenu(false);
  }

  return (
    <S.WrapperHeaderLogged>
      <S.HeaderLogo>
        <img src={Logo} className={'logo'} />
        {/* <span>{t('common.projectName')}</span> */}
      </S.HeaderLogo>
      <S.Menus>
        <Menus />
      </S.Menus>

      <S.UserActions>
        <UserActions />
      </S.UserActions>
      <div className="menu-mobile">
        <MenuOutlined onClick={openDropdown} />
        <S.DropdownStyle onCancel={handleCancel} open={openDropdownMenu}>
          <div className="boxcontent margin-top-20">
            <S.StyleItemMenu
              onClick={() => {
                location.href = '/customer/update-profile';
              }}
              className="item-line margin-top-20"
            >
              <Avatar style={{ backgroundColor: 'rgb(230 236 255)', verticalAlign: 'middle', color: '#85A5FF' }} size={50}>
                {dataUser?.fullName && dataUser?.fullName.slice(0, 1)}
              </Avatar>
              <span style={{ marginLeft: '9px' }}> {dataUser?.fullName && dataUser?.fullName}</span>
            </S.StyleItemMenu>
            {items &&
              items.map((item) => (
                <S.StyleItemMenu
                  key={item?.key}
                  className="item-line"
                  onClick={() => {
                    location.href = item.url;
                  }}
                >
                  <span>{item?.label}</span>
                </S.StyleItemMenu>
              ))}
          </div>
        </S.DropdownStyle>
      </div>
    </S.WrapperHeaderLogged>
  );
};

export default Header;
