import React, { useState } from 'react';
import * as S from './Button.styles';

interface Props {
  label: string;
  function?: (e: any) => void;
  class?: string | '';
  padding?: string;
  type?: 'btn-white' | 'btn-yellow';
  htmlType?: string;
  loading?: boolean;
}

export default function ButtonDetail(props: Props) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [className, setclassName] = useState(`style-button ${props.class} ${props?.type ? props?.type : props.class}`);
  return (
    <S.StyleButtonDetail style={{ padding: `${props?.padding}` }} loading={props?.loading} htmlType="submit" onClick={props.function} className={className}>
      {props.label}
    </S.StyleButtonDetail>
  );
}
