import { createSlice, createAction, PrepareAction, createAsyncThunk } from '@reduxjs/toolkit';
import { persistToken, persistUserUuid, readToken } from '@app/services/localStorage.service';
import { doResetPwdOtpRequest, doResetPwdOtpUpdate, doResetPwdOtpVerify } from '@app/modules/Auth/Auth.api';
import _ from 'lodash';
import { CustomGrantTokenConfig, ResetPwdOtpRequest, ResetPwdOtpUpdate, ResetPwdOtpVerify } from '@app/modules/Auth/interfaces';
import { ResponseError } from '@app/interfaces/interfaces';

interface AuthInterface {
  token: string;
  mobile: string;
  uuid: string;
  email?: string;
  verified: boolean;
  resetPwd: {
    mobile: string;
    otpVerified: boolean;
    isLoading: boolean;
    dataResp: ResponseError | any;
    grantAccessToken: string;
  };
}

const initialState: AuthInterface = {
  token: readToken(),
  mobile: '',
  email: '',
  uuid: '',
  verified: false,
  resetPwd: {
    mobile: '',
    otpVerified: false,
    isLoading: false,
    dataResp: null,
    grantAccessToken: '',
  },
};

export const doUpdateVerified = createAction<PrepareAction<boolean>>('auth/doUpdateVerified', () => {
  return {
    payload: true,
  };
});

export const doUpdatePreUser = createAction<PrepareAction<{ uuid: string; mobile: string; email: string }>>('auth/doUpdatePreUser', ({ uuid, mobile, email }) => {
  persistUserUuid(uuid);

  return {
    payload: {
      uuid: uuid,
      mobile: mobile,
      email: email,
    },
  };
});

export const doUpdateAccessToken = createAction<PrepareAction<any>>('auth/doUpdateAccessToken', (token: string) => {
  persistToken(token);
  return {
    payload: token,
  };
});

export const doResetPasswdRequest = createAsyncThunk('auth/doResetPasswdRequest', async (params: ResetPwdOtpRequest) => {
  return doResetPwdOtpRequest(params);
});

export const doResetPasswdVerifyOtp = createAsyncThunk('auth/doResetPasswdVerifyOtp', async (params: ResetPwdOtpVerify) => {
  return doResetPwdOtpVerify(params);
});

export const doResetPasswd = createAsyncThunk('auth/doResetPasswd', async (params: ResetPwdOtpUpdate) => {
  const headers = params.headers as CustomGrantTokenConfig;
  return doResetPwdOtpUpdate(_.omit(params, ['headers']), headers);
});

export const doResetPwdUpdateMobile = createAction<PrepareAction<string>>('auth/doResetPwdUpdateMobile', (mobile: string) => {
  return {
    payload: mobile,
  };
});

export const doResetPwdOtpVerified = createAction<PrepareAction<{ grantAccessToken: string }>>('auth/doResetPwdOtpVerified', (token: string) => {
  return {
    payload: {
      grantAccessToken: token,
    },
  };
});

export const doClearResetPwdStates = createAction<PrepareAction<any>>('auth/doClearResetPwdStates', () => {
  return { payload: true };
});

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doResetPwdUpdateMobile, (state, action) => {
      state.resetPwd.dataResp = null;
      state.resetPwd.mobile = action.payload;
    });
    builder.addCase(doResetPwdOtpVerified, (state, action) => {
      state.resetPwd.dataResp = null;
      state.resetPwd.otpVerified = true;
      state.resetPwd.grantAccessToken = action.payload.grantAccessToken;
    });
    builder.addCase(doUpdateVerified, (state) => {
      state.verified = true;
    });
    builder.addCase(doUpdateAccessToken, (state, action) => {
      state.token = action.payload;
    });
    builder.addCase(doUpdatePreUser, (state, action) => {
      state.uuid = action.payload.uuid;
      state.mobile = action.payload.mobile;
      state.email = action.payload.email;
    });

    builder.addCase(doResetPasswdRequest.pending, (state) => {
      state.resetPwd.isLoading = true;
    });
    builder.addCase(doResetPasswdRequest.fulfilled, (state, action) => {
      state.resetPwd.isLoading = false;
      state.resetPwd.dataResp = action.payload;
    });
    builder.addCase(doResetPasswdRequest.rejected, (state) => {
      state.resetPwd.isLoading = false;
    });

    builder.addCase(doResetPasswd.pending, (state) => {
      state.resetPwd.isLoading = true;
    });
    builder.addCase(doResetPasswd.fulfilled, (state, action) => {
      state.resetPwd.isLoading = false;
      state.resetPwd.dataResp = action.payload;
    });
    builder.addCase(doResetPasswd.rejected, (state) => {
      state.resetPwd.isLoading = false;
    });

    builder.addCase(doResetPasswdVerifyOtp.pending, (state) => {
      state.resetPwd.isLoading = true;
    });
    builder.addCase(doResetPasswdVerifyOtp.fulfilled, (state, action) => {
      state.resetPwd.isLoading = false;
      state.resetPwd.dataResp = action.payload;
    });
    builder.addCase(doResetPasswdVerifyOtp.rejected, (state) => {
      state.resetPwd.isLoading = false;
    });

    builder.addCase(doClearResetPwdStates, (state) => {
      state.resetPwd.isLoading = false;
      state.resetPwd.dataResp = null;
      state.resetPwd.mobile = '';
      state.resetPwd.otpVerified = false;
      state.resetPwd.grantAccessToken = '';
    });
  },
});

export default authSlice.reducer;
