import { useAppDispatch } from '@app/hooks/reduxHooks';
import { setBreadcrumb, setBreadcrumbTitle } from '@app/store/slices/breadcrumbSlice';
import React, { useContext, useEffect, useState } from 'react';
import * as S from '../LoanManagement.style';
import IconLeftCircle from '@app/assets/icons/arrow-left-circle.svg';
// import IconPartner from '@app/assets/icons/icon-partner.svg';
import * as GS from '../../../styles/GlobalStyle';

// import ButtonDetail from '@app/components/common/buttons/ButtonDetail/ButtonDetail';
import { Col, Row, Tooltip } from 'antd';
import { StatusesText } from '@app/components/common/Statuses/Statuses';
import { getLoanManagementDetailApi } from '../LoanManagement.api';
import { ResponseDataDetail, ResponseDataFund } from '../interface';
import _ from 'lodash';
import { formatNumberWithCommas } from '@app/utils/utils';
import Paragraph from 'antd/lib/typography/Paragraph';
import { LoanManagementContext, LoanManagementProvider } from '../Context';
// import ModalSectionPayment from '../SectionPayment/ModalSectionPayment';
import { useParams } from 'react-router-dom';
// import { updateModalLoanManagement } from '../Action';

function DetailHTML() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { stateLoanManagement, dispatchLoanManagement } = useContext(LoanManagementContext);
  const { code } = useParams();
  const dispatch = useAppDispatch();
  const [data, setData] = useState<ResponseDataDetail>();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [dataFund, setdataFund] = useState<ResponseDataFund | null>(null);

  const callApiLoanManagementDetail = async () => {
    await getLoanManagementDetailApi(code)
      .then((res) => {
        if (_.size(res.error)) {
          // @TODO Hiển thị lỗi lấy dữ liệu danh sách khách hàng
        } else {
          setData(res);
        }
      })
      .catch((err) => {
        console.log(err);
        setData(undefined);
      });
  };

  // const callgetLoanManagementDetailFundApi = async () => {
  //   data &&
  //     (await getLoanManagementDetailFundApi(data?.fundId)
  //       .then((res) => {
  //         if (_.size(res.error)) {
  //           // @TODO Hiển thị lỗi lấy dữ liệu danh sách khách hàng
  //         } else {
  //           setdataFund(res);
  //         }
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       }));
  // };
  function moveBack() {
    history.back();
  }

  // function openModalPayment() {
  //   dispatchLoanManagement(updateModalLoanManagement({ ...stateLoanManagement?.modals, isModalOpenPayment: true }));
  // }

  useEffect(() => {
    dispatch(
      setBreadcrumb([
        {
          name: 'Quản lý giao dịch',
          url: '/loan-management',
        },
        {
          name: <S.StyleBreadCrumb className="breadcrumb-css-code">{code}</S.StyleBreadCrumb>,
        },
      ]),
    );

    return () => {
      dispatch(setBreadcrumb([]));
      dispatch(setBreadcrumbTitle(''));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    callApiLoanManagementDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  // useEffect(() => {
  //   callgetLoanManagementDetailFundApi();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [data]);

  return (
    <GS.WrapperOverrideInput>
      <S.LoanDetail>
        <Row className="section-title">
          <div className="box-title">
            <img className="cursor-pointer" src={IconLeftCircle} alt="" onClick={moveBack} />
            <div className="text-title">{data ? data?.code : ''}</div>
          </div>
          {/* {(data && data?.status == 'CANCELED') || data?.status == 'REJECTED' ? '' : <ButtonDetail label="Thanh toán" function={openModalPayment}></ButtonDetail>} */}
        </Row>
        <div className="section-content">
          <Col className="col-1">
            <Row className="section-infor-transaction">
              <Row className="title-transaction">Thông tin giao dịch</Row>

              <Row className="content">
                <Row className="box-content">
                  <div className="item padding-top-0 ">
                    <div className="branch">Sản phẩm</div>
                    <div className="value">
                      {/* <img src={IconCopy} className="style-icon-coppy" alt="" /> */}
                      <Paragraph className="value-code-coppy-text" copyable={{ tooltips: false }}>
                        {data?.productCode ? data?.productCode : '-'}
                      </Paragraph>
                      <Tooltip placement="top" title={<span>{data?.productCode ? data?.productCode : ''}</span>}>
                        <div className="value-mobile-data-code">{data?.productCode ? data?.productCode : '-'}</div>
                      </Tooltip>
                    </div>
                  </div>
                  <div className="item">
                    <div className="branch">Mã giao dịch</div>
                    <div className="value">
                      {/* <img src={IconCopy} className="style-icon-coppy" alt="" /> */}
                      <Paragraph className="value-code-coppy-text" copyable={{ tooltips: false }}>
                        {data ? data?.code : '-'}
                      </Paragraph>
                      <Tooltip placement="top" title={<span>{data?.code ? data?.code : ''}</span>}>
                        <div className="value-mobile-data-code">{data ? data?.code : '-'}</div>
                      </Tooltip>
                    </div>
                  </div>

                  <div className="item">
                    <div className="branch">Mục đích ứng vốn</div>
                    <Tooltip placement="top" title={<span>{data ? data?.purpose : ''}</span>}>
                      <div className="value">{data?.purpose ? data?.purpose : '-'}</div>
                    </Tooltip>
                  </div>
                  <div className="item">
                    <div className="branch">Trạng thái</div>
                    <div className="value">{data ? <StatusesText context="LOAN" status={data && data?.status} /> : ''}</div>
                  </div>
                  {data && data?.status == 'CANCELED' ? (
                    <div className="item">
                      <div className="branch">Lý do huỷ</div>
                      <Tooltip placement="top" title={<span>{data?.cancelReason ? data?.cancelReason : ''}</span>}>
                        <div className="value value-red">{data?.cancelReason ? data?.cancelReason : '-'}</div>
                      </Tooltip>
                    </div>
                  ) : (
                    ''
                  )}
                  {data && data?.status == 'REJECTED' ? (
                    <div className="item">
                      <div className="branch">Lý do từ chối </div>
                      <Tooltip placement="top" title={<span>{data?.cancelReason ? data?.cancelReason : ''}</span>}>
                        <div className="value value-red">{data?.cancelReason ? data?.cancelReason : '-'}</div>
                      </Tooltip>
                    </div>
                  ) : (
                    ''
                  )}
                  <div className="item">
                    <div className="branch">{data?.loanType && data?.loanType == 'COD' ? 'Mã vận chuyển' : 'Mã đơn hàng'}</div>
                    {/* ///ko con transport id */}
                    <Tooltip placement="top" title={<span>{data?.refId ? data?.refId : '-'}</span>}>
                      <div className="value value-blue">{data?.refId ? data?.refId : '-'}</div>
                    </Tooltip>
                  </div>

                  <div className="item">
                    <div className="branch">Ngày duyệt yêu cầu</div>
                    <Tooltip placement="top" title={<span>{data?.approvalDate ? data?.approvalDate : ''}</span>}>
                      <div className="value ">{data && data?.approvalDate ? data?.approvalDate : '-'}</div>
                    </Tooltip>
                  </div>
                  <div className="item border-bottom-none">
                    <div className="branch">Ngày giải ngân gần nhất</div>
                    <Tooltip placement="top" title={<span>{data?.disbursementDate ? data?.disbursementDate : ''}</span>}>
                      <div className="value ">{data && data?.disbursementDate ? data?.disbursementDate : '-'}</div>
                    </Tooltip>
                  </div>
                </Row>
              </Row>
            </Row>
          </Col>
          <Col className="col-2">
            <div className="section-infor-amount">
              <Row className="title-amount">Thông tin tiền ứng</Row>
              <Row className="content">
                <Row className="box-content">
                  <div className="item padding-top-0 ">
                    <div className="branch">Tổng tiền ứng</div>
                    <Tooltip placement="top" title={<span>{data ? formatNumberWithCommas(data?.loanAmount) : '0'}</span>}>
                      <div className="value">{data ? formatNumberWithCommas(data?.loanAmount) : '0'}</div>
                    </Tooltip>
                  </div>
                  <div className="item">
                    <div className="branch">Tổng tiền đã giải ngân</div>
                    <Tooltip placement="top" title={<span>{data ? formatNumberWithCommas(data?.disbursedAmount) : '0'}</span>}>
                      <div className="value">{data ? formatNumberWithCommas(data?.disbursedAmount) : '0'}</div>
                    </Tooltip>
                  </div>
                  <div className="item">
                    <div className="branch">Phí quản lý ứng vốn</div>
                    <Tooltip placement="top" title={<span>{data ? formatNumberWithCommas(data?.accruedFeeAmount) : '0'}</span>}>
                      <div className="value">{data ? formatNumberWithCommas(data?.accruedFeeAmount) : '0'}</div>
                    </Tooltip>
                  </div>
                  <div className="item">
                    <div className="branch">Số tiền đã thanh toán</div>
                    <Tooltip placement="top" title={<span>{data ? formatNumberWithCommas(data?.interestPaidAmount + data?.principalPaidAmount + data?.feePaidAmount) : '0'}</span>}>
                      <div className="value">{data ? formatNumberWithCommas(data?.interestPaidAmount + data?.principalPaidAmount + data?.feePaidAmount) : '0'}</div>
                    </Tooltip>
                  </div>

                  <div className="item border-bottom-none ">
                    <div className="branch">Tổng tiền tạm tính</div>
                    <Tooltip
                      placement="top"
                      title={
                        <span>
                          {data ? formatNumberWithCommas(data?.disbursedAmount + data?.accruedFeeAmount - (data?.interestPaidAmount + data?.principalPaidAmount + data?.feePaidAmount)) : '0'}
                        </span>
                      }
                    >
                      <div className="value value-blue">
                        {data ? formatNumberWithCommas(data?.disbursedAmount + data?.accruedFeeAmount - (data?.interestPaidAmount + data?.principalPaidAmount + data?.feePaidAmount)) : '0'}
                      </div>
                    </Tooltip>
                  </div>
                </Row>
              </Row>
            </div>
            <div className="section-infor-amount margin-top-24">
              <Row className="title-amount">Thanh toán</Row>
              <Row className="content padding-bottom-0">
                <Row className="box-content">
                  <div className="item padding-top-0 border-bottom-none">
                    <div className="branch">
                      {data?.loanType && data?.loanType == 'COD'
                        ? 'Khoản ứng vốn sẽ được thanh toán khi đơn hàng được giao và đơn vị vận chuyển nhận tiền COD thành công từ người nhận'
                        : `Để thanh toán khoản ứng vốn này, khách hàng vui lòng thực hiện thanh toán bằng cách nạp tiền vào ví của ${
                            data?.partnerName ? data?.partnerName : ''
                          } bằng phương thức chuyển khoản
                      hoặc tiền mặt khi nhận hàng.`}
                    </div>
                  </div>
                </Row>
              </Row>
            </div>
          </Col>
          {/* <Col className="col-2">
            <div className="box-content-col2">
              <Row className="title-partner">Đối tác ứng vốn</Row>
              <Row className="content">
                <Col className="col-avatar">
                  <img src={IconPartner} className="style-icon-partner" alt="" />
                </Col>
                <Col className="col-infor">
                  <div className="item padding-top-0 ">
                    <div className="branch">Tên công ty</div>
                    <div className="value">{dataFund ? dataFund?.name : '-'}</div>
                  </div>
                  <div className="item">
                    <div className="branch">Website</div>
                    <div className="value">{dataFund ? dataFund?.website : '-'}</div>
                  </div>
                  <div className="item">
                    <div className="branch">Số điện thoại</div>
                    <div className="value">{dataFund ? dataFund?.phone : '-'}</div>
                  </div>
                  <div className="item">
                    <div className="branch">Số Tax</div>
                    <div className="value">{dataFund ? dataFund?.tax : '-'}</div>
                  </div>
                  <div className="item">
                    <div className="branch">Địa chỉ</div>
                    <div className="value">{dataFund ? dataFund?.address : '-'} </div>
                  </div>

                  <div className="item border-bottom-none">
                    <div className="branch">Thông tin công ty</div>
                    <div className="value">{dataFund ? dataFund?.description : '-'}</div>
                  </div>
                </Col>
              </Row>
            </div>
          </Col> */}
        </div>
      </S.LoanDetail>
      {/* {data && <ModalSectionPayment data={data}></ModalSectionPayment>} */}
    </GS.WrapperOverrideInput>
  );
}

export default function Detail() {
  return (
    <LoanManagementProvider>
      {/* <GS.LayoutPaddingScreen> */}
      <DetailHTML></DetailHTML>
      {/* </GS.LayoutPaddingScreen> */}
    </LoanManagementProvider>
  );
}
