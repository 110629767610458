import styled, { createGlobalStyle } from 'styled-components';
import { resetCss } from './resetCss';
import { BREAKPOINTS, FONT_SIZE, FONT_WEIGHT } from './themes/constants';
import { lightThemeVariables, darkThemeVariables, commonThemeVariables, antOverrideCssVariables } from './themes/themeVariables';
import { MultiSelect } from 'react-multi-select-component';
import { media } from '@app/styles/themes/constants';

export default createGlobalStyle`


.ant-btn:active,
  .ant-btn:focus {
    text-decoration: none;
    color: black;
    background-color: rgb(242, 192, 21);  
  }
  th.ant-table-cell {
    text-align: left !important;
  }
  .ant-table-tbody>tr.ant-table-row:hover>td, .ant-table-tbody>tr>td.ant-table-cell-row-hover {
      background: rgb(250, 250, 250) !important;
  }
  .ant-typography {
    margin-bottom: 0 !important;
  }
  .ant-typography-copy {
      margin-left: 10px;
      font-size: 16px;
      color: #C7CED7;
  }
  .ant-select-selector {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #3D536F;
      padding: 0 16px;
      border: 1px solid #D4DBE7 !important;
  }

  td.ant-table-cell {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: #2A3B50;
  }
  .ant-table-wrapper {
    padding-right: 24px;
  }
  .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    background-color: #D4DBE7;
  }
  ${resetCss}

  [data-theme='light'],
  :root {
    ${lightThemeVariables}
  }

  [data-theme='dark'] {
    ${darkThemeVariables}
  }

  :root {
    ${commonThemeVariables};
    ${antOverrideCssVariables};
  } 

  [data-no-transition] * {
    transition: none !important;
  }
  
  .range-picker {
    & .ant-picker-panels {
      @media only screen and ${media.xs} and (max-width: ${BREAKPOINTS.md - 0.02}px) {
        display: flex;
      flex-direction: column;
      }
    }
  }

  .search-dropdown {
    box-shadow: var(--box-shadow);

    @media only screen and ${media.xs} and (max-width: ${BREAKPOINTS.md - 0.02}px)  {
      width: calc(100vw - 16px);
    max-width: 600px;
    }

    @media only screen and ${media.md} {
      max-width: 323px;
    }
  }

  a {
    color: #597EF7;
    &:hover,:active {
      color: var(--ant-primary-color-hover);
    }
  }
  
  .d-none {
    display: none;
  }

  .ant-picker-cell {
    color: var(--text-main-color);
  }

  .ant-picker-cell-in-view .ant-picker-calendar-date-value {
    color: var(--text-main-color);
    font-weight: ${FONT_WEIGHT.bold};
  }

  .ant-picker svg {
    color: var(--text-light-color);
  }

  // notifications start
  .ant-notification-notice {
    width: 36rem;
    padding: 2rem;
    min-height: 6rem;
    
    .ant-notification-notice-with-icon .ant-notification-notice-message {
      margin-bottom: 0;
      margin-left: 2.8125rem;
    }

    .ant-notification-notice-with-icon .ant-notification-notice-description {
      margin-left: 4.375rem;
      margin-top: 0;
    }

    .ant-notification-notice-icon {
      font-size: 2.8125rem;
      margin-left: 0
    }

    .ant-notification-notice-close {
      top: 1.25rem;
      right: 1.25rem;
    }

    .ant-notification-notice-close-x {
      display: flex;
      font-size: 0.9375rem;
    }

    .notification-without-description {
      .ant-notification-notice-close {
        top: 1.875rem;
      }
      .ant-notification-notice-with-icon .ant-notification-notice-description  {
        margin-top: 0.625rem;
      }
    }
    
    .title {
      font-size: ${FONT_SIZE.xxl};
      height: 3rem;
      margin-left: 1.5rem;
      display: flex;
      align-items: center;
      font-weight: ${FONT_WEIGHT.bold};

      &.title-only {
        color: var(--text-main-color);
        font-size: ${FONT_SIZE.md};
        height: 2rem;
        line-height: 2rem;
        margin-left: 0.75rem;
        font-weight: ${FONT_WEIGHT.semibold};
      }
  }
  
    .description {
      color: #404040;
      font-size: ${FONT_SIZE.md};
      font-weight: ${FONT_WEIGHT.semibold};
      line-height: 1.375rem;
    }
  
    &.ant-notification-notice-success {
      border: 1px solid var(--success-color);
      background: var(--notification-success-color);
      
      .title {
        color: var(--success-color);
      }
    }
  
    &.ant-notification-notice-info {
      border: 1px solid var(--primary-color);
      background: var(--notification-primary-color);
  
      .title {
        color: var(--primary-color);
      }
    }
  
    &.ant-notification-notice-warning {
      border: 1px solid var(--warning-color);
      background: var(--notification-warning-color);
  
      .title {
        color: var(--warning-color);
      }
    }
  
    &.ant-notification-notice-error {
      border: 1px solid var(--error-color);
      background: var(--notification-error-color);
  
      .title {
        color: var(--error-color);
      }
    }
  
    .success-icon {
      color: var(--success-color);
    }
  
    .info-icon {
      color: var(--primary-color);
    }
  
    .warning-icon {
      color: var(--warning-color);
    }
  
    .error-icon {
      color: var(--error-color);
    }
  }
  
  .ant-menu-inline, .ant-menu-vertical {
    border-right: 0;
  }
  // notifications end
  
  .ant-menu-inline-collapsed-tooltip {
    .ant-tooltip-content {
      display: none;
    }
  }

  & .ant-input,
  & .ant-input-affix-wrapper {
    padding: 3px 30px 3px 13px;
    border-radius: 2px;
    border: 1px solid #D4DBE7;
    background: white;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;

    &::placeholder {
      color: #899fbb;
    }
  }

  & .ant-checkbox {
    & .ant-checkbox-inner {
      border-radius: 4px;
    }
  }

  & .ant-btn {
    font-weight: 400;
    border-radius: 4px;
  }
  
  & .ant-btn.ant-btn-primary {
    color: #000c17;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    text-shadow: none;
  }
  
  & .ant-btn-default[disabled],
  & .ant-btn-primary[disabled] {
    background: #FBD448;
    border-radius: 4px;
    border: none;
  }
  
  & .ant-form-item-control {
    & .ant-form-item-explain {
      margin: 10px 0;
      
      & .ant-form-item-explain-error {
        font-size: 14px;
      }
    }
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 2px;
  }
  & .ant-input[disabled] {
    background-color: #FAFAFA;
    border-color: #F5F5F5;
  }
  
  & .ant-form-item-control-input {
    min-height: unset;
  }
  
  & input:placeholder-shown {
    background-color: white;
  }
  
  & .ant-modal-title {
    font-size: 16px;
  }
`;

export const LayoutFullScreen = styled.div`
  /* @media only screen and ${media.xl} {
    padding: 12px 160px;
  }

  @media only screen and ${media.xs} {
    padding: 12px 30px;
  }

  @media only screen and ${media.sm} {
    padding: 12px 30px;
  } */
`;
export const LayoutPaddingScreen = styled.div`
  @media only screen and ${media.xl} {
    padding: 12px 160px;
  }

  @media only screen and ${media.xs} {
    padding: 12px 30px;
  }

  @media only screen and ${media.sm} {
    padding: 12px 30px;
  }
`;

export const StyleBreadCrumb = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #597ef7;
`;
export const WrapperOverrideInput = styled.div`
  .err-text-validate {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #ff4d4f !important;
    margin-bottom: 15px;
    .underline-err-text {
      text-decoration: underline;
      cursor: pointer;
      margin-left: 10px;
    }
  }
  & .ant-btn-default {
    height: 33px;
    line-height: 0;
    border-radius: 2px;
    border: 1px solid white;
    display: flex;
    align-items: center;
    gap: 10px;

    & span {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
    }
  }

  & .ant-table-container {
    & td.ant-table-cell,
    & th.ant-table-cell {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      background: #fafafa;
    }
  }

  & .ant-pagination-disabled .ant-pagination-item-link,
  .ant-pagination-disabled:focus-visible .ant-pagination-item-link,
  .ant-pagination-disabled:hover .ant-pagination-item-link {
    color: gray;
  }

  & .ant-pagination-jump-next,
  .ant-pagination-jump-prev,
  .ant-pagination-next,
  .ant-pagination-prev,
  .ant-pagination-item {
    min-width: 32px;
    height: 32px;
    line-height: 32px;
    border-radius: 2px;

    & button {
      border-radius: 2px;
      border: 1px solid #3c4450;
    }
  }

  & .ant-table-tbody > tr.ant-table-row:hover > td[class*='ant-table-cell-fix-'],
  & td[class*='ant-table-cell-fix-'],
  & th[class*='ant-table-cell-fix-'] {
  }

  & .ant-checkbox {
    & .ant-checkbox-inner {
    }
    &:hover {
      .ant-checkbox-inner {
      }
    }
  }

  & .ant-table-tbody > tr.ant-table-row-selected:hover > td {
    background: none;
  }
`;

export const MulSelect = styled(MultiSelect)<{ defaultTitle?: string }>`
  & .dropdown-container {
    background: none;
    border-radius: 2px;
    border: 1px solid #3c4450;
    width: 149px;
    height: 33px;
    align-items: center;
    display: flex;

    & .dropdown-heading {
      & .dropdown-heading-value {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;

        & span.gray {
          display: none;
        }
      }
      & .dropdown-heading-value:after {
        content: '${(props) => props?.defaultTitle ?? 'Select ...'}';
      }
    }
  }

  & .dropdown-content {
    z-index: 1000;
    right: 0;

    & .panel-content {
      background: #212830;

      & .select-item {
        & span {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
        }
      }
      & .select-item:hover,
      & .select-item.selected {
        background: #1a1f25;
      }
    }
  }
`;
