import { Button } from 'antd';
import React from 'react';

type Props = {
  label: string;
  onClick?: () => void;
  margin?: string;
  loading?: boolean;
};

export default function ButtonYellow(props: Props) {
  return (
    <Button
      loading={props?.loading}
      onClick={props?.onClick}
      style={{ height: 'fit-content', padding: '6px 16px', fontSize: '12px', background: '#fcd549', margin: `${props?.margin}` }}
      htmlType={'submit'}
    >
      {props?.label}
    </Button>
  );
}
