import React, { useEffect } from 'react';
import { getQueryParams } from '@app/utils/utils';
import { InforPartnerApi } from './Partner.api';
export default function LoadingPageConnectPartner() {
  useEffect(() => {
    async function fetch() {
      await InforPartnerApi(getQueryParams().partner_id)
        .then((res) => {
          localStorage.setItem('inforUserPartner', JSON.stringify(res));
        })
        .catch((err) => {
          console.log(err);
        });
      localStorage.setItem('paramsPartner', JSON.stringify(getQueryParams()));
      sessionStorage.setItem('paramsInforUserPartner', JSON.stringify(getQueryParams()));
      if (localStorage.getItem('accessToken')) {
        await (location.href = '/auth/partner/confirm-connect');
      } else {
        await (location.href = `/auth/login/connect-partner`);
      }
    }
    fetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {}, []);

  return <div></div>;
}
