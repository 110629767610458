// import { ArrowRightOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import IconImport from '@app/assets/icons/icon-import.svg';
import IconCOD from '@app/assets/icons/icon-COD.svg';
import React from 'react';
import ButtonYellow from '@app/components/common/buttons/ButtonYellow/ButtonYellow';

export default function SectionProduct() {
  function movePageCod() {
    location.href = '/product/cod';
  }
  return (
    <Col className="product">
      <Row className="title">Sản phẩm, dịch vụ ứng vốn</Row>
      <Row className="content">
        <Col className="item">
          <div className="content">
            <div className="title-import">Ứng vốn nhập hàng</div>
            <div className="text">Khách hàng tham gia chương trình có thể đăng ký để nhận tiền COD từ BiFin ngay sau khi hoàn thành việc giao hàng cho đối tác vận chuyển.</div>
            {/* <div className="detail">
              <div className="box-detail">
                <div className="text-detail-import">Xem danh sách tối tác</div>
                <ArrowRightOutlined className="style-icon-arrow-import" />
              </div>
            </div> */}
            <div className="section-btn"></div>
          </div>
          <div className="image">
            <img src={IconImport} alt="" />
          </div>
        </Col>
        <Col className="item itemCOD">
          <div className="content">
            <div className="title-cod">Ứng vốn COD</div>
            <div className="text">Quý doanh nghiệp tham gia chương trình có thể đăng ký để nhận tiền COD từ BiFin sau khi họ hoàn thành việc giao hàng cho đối tác vận chuyển.</div>
            {/* <div className="detail">
              <div className="box-detail">
                <div className="text-detail-cod">Xem danh sách tối tác</div>
                <ArrowRightOutlined className="style-icon-arrow-cod" />
              </div>
            </div> */}
            <div className="section-btn">
              <ButtonYellow onClick={movePageCod} label="Ứng vốn COD ngay"></ButtonYellow>
            </div>
          </div>
          <div className="image">
            <img src={IconCOD} alt="" />
          </div>
        </Col>
      </Row>
    </Col>
  );
}
